import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import _ from "lodash";
import { JSONTree } from "react-json-tree";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/themes/prism.css";

function AttemptsWithCommands({ agent = null }) {
  const [llmCalls, setLlmCalls] = useState([]);
  const [fetchedLlmCalls, setFetchedLlmCalls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [showFullPrompt, setShowFullPrompt] = useState(false);
  const popupRef = useRef(null);
  const [actions, setActions] = useState([]);
  const [combinedEvents, setCombinedEvents] = useState([]);

  const fetchData = () => {
    const endpoint = getEndpoint();

    if (!endpoint) {
      setCombinedEvents([]);
      setHasFetched(false);
      return;
    }

    Promise.all([
      axios.get(endpoint),
      axios.get(`${process.env.REACT_APP_API_URL}/actions/${agent.id}`),
    ])
      .then(([llmResponse, actionsResponse]) => {
        const llmCalls = llmResponse.data.map((call) => ({
          ...call,
          type: "llm_call",
        }));
        const actions = actionsResponse.data.map((action) => ({
          ...action,
          type: "action",
        }));

        const combined = [...llmCalls, ...actions].sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);

          // Compare dates
          if (dateA.toDateString() !== dateB.toDateString()) {
            return dateB - dateA; // Most recent day first
          }

          // If same day, sort chronologically within the day
          return dateA - dateB;
        });

        setCombinedEvents(combined);
        setHasFetched(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
        setError("Error fetching data");
        setIsLoading(false);
      });
  };

  const formatCommand = (command) => {
    let formattedCommand = "";
    let commandObj = {};

    try {
      commandObj = JSON.parse(command);
      let text = commandObj.args.text || commandObj.args.write_string || "";
      let language =
        commandObj.name === "write_to_file" ? "python" : "javascript";
      formattedCommand = Prism.highlight(
        text,
        Prism.languages[language],
        language
      );
      delete commandObj.args.text;
      delete commandObj.args.write_string;
    } catch (e) {
      formattedCommand = command;
    }

    let remainingCommand = Prism.highlight(
      JSON.stringify(commandObj, null, 2),
      Prism.languages.json,
      "json"
    );

    return {
      formattedCommand,
      remainingCommand,
    };
  };

  const renderCommand = (command) => {
    let { formattedCommand, remainingCommand } = formatCommand(command.command);
    return (
      <div>
        {remainingCommand && (
          <pre
            style={{
              whiteSpace: "pre-wrap",
              border: "1px solid black",
              backgroundColor: "white",
            }}
          >
            <code dangerouslySetInnerHTML={{ __html: remainingCommand }} />
          </pre>
        )}
        {formattedCommand && (
          <pre
            style={{
              whiteSpace: "pre-wrap",
              border: "1px solid black",
              backgroundColor: "white",
            }}
          >
            <code dangerouslySetInnerHTML={{ __html: formattedCommand }} />
          </pre>
        )}
      </div>
    );
  };

  const formatCompletion = (completion) => {
    let completionObj = {};
    try {
      completionObj = JSON.parse(completion);
      for (let key in completionObj) {
        try {
          completionObj[key] = JSON.parse(completionObj[key]);
        } catch (e) {
          // not a JSON string, leave it as is
        }
      }
      delete completionObj.text;
      delete completionObj.write_string;
      delete completionObj.commands;
    } catch (e) {
      completionObj = completion;
    }

    let completionText = Prism.highlight(
      JSON.stringify(completionObj, null, 2),
      Prism.languages.json,
      "json"
    );

    return completionText;
  };

  useEffect(() => {
    let intervalId = null;

    fetchData();

    intervalId = setInterval(fetchData, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [agent]);

  const getEndpoint = () => {
    if (agent) {
      return `${process.env.REACT_APP_API_URL}/llm_calls/${agent.id}`;
    }
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowFullPrompt(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!_.isEqual(fetchedLlmCalls, llmCalls)) {
      setIsLoading(true);
      setLlmCalls(fetchedLlmCalls);
      setIsLoading(false);
      setHasFetched(true);
    }
  }, [llmCalls, fetchedLlmCalls]);

  const toggleFullPrompt = (event, prompt) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    setSelectedPrompt(prompt);
    setShowFullPrompt(!showFullPrompt);
  };

  const renderEvent = (event) => {
    if (event.type === "llm_call") {
      return (
        <button
          className={`attempt attempt-${event.status}`}
          onClick={(e) => toggleFullPrompt(e, event.input)}
        >
          <strong>LLM Call ID: </strong> {event.id}
          <br />
          <strong>Date:</strong> {new Date(event.created_at).toLocaleString()}
          <br />
          <strong>Model Name:</strong> {event.model_name}
          <br />
          <strong>Temperature:</strong> {event.temperature}
          <br />
          <strong>Input Tokens:</strong> {event.input_tokens}
          <br />
          <strong>Completion Tokens:</strong> {event.completion_tokens}
          <br />
          <strong>Cost: </strong>$
          {Number(event.cost)
            .toFixed(6)
            .replace(/\.?0+$/, "")}
          <br />
          <strong>Triggered By:</strong>{" "}
          {event.trigger_action_id !== null
            ? `action ID ${event.trigger_action_id}`
            : event.trigger_workflow_instance_id !== null
              ? `workflow ID ${event.trigger_workflow_instance_id}`
              : "N/A"}
          <br />
          <br />
          <strong>Completion:</strong>
          <br />
          <br />
          <pre
            style={{
              whiteSpace: "pre-wrap",
              border: "1px solid black",
              backgroundColor: "white",
            }}
          >
            <code
              dangerouslySetInnerHTML={{
                __html: formatCompletion(event.completion),
              }}
            />
          </pre>
        </button>
      );
    } else if (event.type === "action") {
      const { type, ...actionWithoutType } = event;
      return (
        <button className={`attempt attempt-${event.status}`}>
          <strong>Action ID:</strong> {event.id}
          <br />
          <strong>Date:</strong> {new Date(event.created_at).toLocaleString()}
          <br />
          <strong>LLM Call ID:</strong> {event.llm_call_id || "N/A"}
          <br />
          <strong>Action:</strong>
          <br />
          <pre
            style={{
              whiteSpace: "pre-wrap",
              border: "1px solid black",
              backgroundColor: "white",
            }}
          >
            <code
              dangerouslySetInnerHTML={{
                __html: Prism.highlight(
                  JSON.stringify(actionWithoutType, null, 2),
                  Prism.languages.json,
                  "json"
                ),
              }}
            />
          </pre>
        </button>
      );
    }
  };

  if (isLoading || (!hasFetched && agent)) {
    return <div className="AttemptsWithCommands">Loading...</div>;
  }

  if (error) {
    return <div className="AttemptsWithCommands">{error}</div>;
  }

  const theme = {
    base00: "#f7f7f7" /* background color */,
    base03: "#f7f7f7" /* item number color */,
    base0B: "#2b2b2b" /* text color */,
    base0D: "#247BA0" /* key color */,
  };

  return (
    <div className="AttemptsWithCommands">
      {combinedEvents.map((event) => (
        <div key={`${event.type}-${event.id}`}>
          {renderEvent(event)}
          {selectedPrompt === event.input && showFullPrompt && (
            <div
              className="popup"
              ref={popupRef}
              onClick={(e) => toggleFullPrompt(e, event.input)}
            >
              <div
                className="popup-content"
                onClick={(e) => e.stopPropagation()}
              >
                <h3>Full Prompt:</h3>
                <JSONTree
                  data={JSON.parse(event.input)}
                  theme={theme}
                  invertTheme={false}
                  shouldExpandNodeInitially={() => true}
                />
                <button
                  className="close-button"
                  onClick={() => toggleFullPrompt(event.input)}
                >
                  <strong>Close</strong>
                </button>
              </div>
            </div>
          )}{" "}
        </div>
      ))}
    </div>
  );
}

export default AttemptsWithCommands;
