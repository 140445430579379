import React from "react";
import config from "../config";

const EnvSwitcher = () => {
  const switchEnvironment = () => {
    const targetUrl = config.env === "prod" ? config.devUrl : config.prodUrl;
    window.location.href = targetUrl;
  };

  const isProd = config.env === "prod";
  const buttonText = isProd ? "Switch to Development" : "Switch to Production";
  const buttonClass = `env-switcher-button ${isProd ? "dev" : "prod"}`;

  return (
    <button className={buttonClass} onClick={switchEnvironment}>
      {buttonText}
    </button>
  );
};

export default EnvSwitcher;
